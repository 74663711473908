import request from '@/utils/requestV2'

const qs = require('qs')

/**
 * 查询供应商对账单列表
 * @param {Object} data
 */
export function getSupplierBillList (data) {
  return request({
    url: '/ooh-statement/v2/supplierbill/getsupplierbilllist',
    method: 'post',
    data: qs.stringify(data)
  })
}

/**
 * 查询供应商某个结算周期账单
 * @param {Object} data
 */
export function getSupplierBill (data) {
  return request({
    url: '/ooh-statement/v2/supplierbill/getsupplierbill',
    method: 'post',
    data: qs.stringify(data)
  })
}
