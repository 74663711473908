<template>
  <div>
     <Row :gutter="8" class="p-b-10">
      <i-col :xs="24" :sm="12" :md="4" :lg="4"  class="m-b-5" v-if="currentUserType!==2">
        <Select v-model="demandCompanyId"  @change="demandComponyChange">
          <Option v-for="(demandCompany,index) in demandCompanies" :key="index" :value="demandCompany.id">{{demandCompany.name}}</Option>
        </Select>
      </i-col>
      <i-col :xs="24" :sm="12" :md="4" :lg="4"  class="m-b-5" v-if="currentUserType!==3">
        <Select v-model="supplierCompanyId">
          <Option v-for="(supplierCompany,index) in supplierCompanies" :key="index" :value="supplierCompany.id">{{supplierCompany.name}}</Option>
        </Select>
      </i-col>
      <i-col :xs="24" :sm="12" :md="4" :lg="4"  class="m-b-5">
        <DatePicker
        type="year"
        v-model="year"
        placeholder="选择年份"
        style="width:100%"
        :transfer="true"
        :clearable="false"
      ></DatePicker>
      </i-col>
      <i-col :xs="24" :sm="12" :md="4" :lg="4" class="m-b-5">
        <i-button size="small" icon="ios-search" type="primary" @click="handleSearch">搜索</i-button>
      </i-col>
    </Row>
    <Table
      border stripe
      :loading="dataLoading"
      :data="tableData"
      :columns="dataColumns"
      show-summary
      :summary-method="handleSummary"
    ></Table>
  </div>
</template>

<script>
import { toMoney } from '@/utils/wnumb_own'
import { getDispatcherList, getSupplierCompany } from '@/api/os/company'
import { getSupplierBillList } from '@/api/statement/supplierbill'
export default {
  props: {
    publisherId: {
      type: Number
    },
    companyId: {
      type: Number
    }

  },
  data () {
    return {
      dataLoading: false,
      currentQuery: {

      },
      year: new Date().getFullYear().toString(),
      currentUserType: 1, // 1 表示业主，2表示派单公司，3表示供应商
      demandCompanies: [], // 派单公司
      demandCompanyId: 0,
      supplierCompanies: [], // 供应公司
      supplierCompanyId: 0,

      tableData: [],
      dataColumns: [
        { title: '周期', align: 'center', key: 'name' },
        {
          title: '计费',
          align: 'center',
          key: 'executedAmount',
          render: (h, param) => {
            return h('span', toMoney(param.row.executedAmount))
          }
        },
        { title: '状态', align: 'center', key: 'statusName' },
        {
          title: '操作',
          align: 'center',
          render: (h, param) => {
            return h('a', {
              on: {
                click: () => {
                  this.$emit('on-showDetail', { currentUserType: this.currentUserType, demandCompanyId: this.currentQuery.demandCompanyId, supplierCompanyId: this.currentQuery.supplierCompanyId, publisherId: this.currentQuery.publisherId, startDate: param.row.startDate, endDate: param.row.endDate })
                }
              }
            }, '详情')
          }

        }
      ]
    }
  },
  created () {
    this.initComponentFileters()
  },
  methods: {
    // 初始化用户类型、筛选条件
    initComponentFileters () {
      const that = this
      getDispatcherList({ companyId: that.publisherId }).then(res => {
        if (res.length) {
          if (res.filter(item => item.id === that.companyId).length) { // 判断当前用户是否是派单公司
            that.currentUserType = 2
            that.demandCompanyId = that.companyId
            that.initSupplierCompanies()
          } else if (that.companyId === that.publisherId) { // 判断当前用户是否是业主
            that.currentUserType = 1
            that.demandCompanies = res
            that.demandCompanyId = res[0].id
            that.initSupplierCompanies()
          } else { // 判断用户为供应商
            that.currentUserType = 3
            that.supplierCompanyId = that.companyId
            that.initDemandCompanies()
          }
        }
      })
    },
    // 供应商获取对应的派单公司
    initDemandCompanies () {
      const that = this
      that.demandCompanyId = 0
      that.demandCompanies = []
      getDispatcherList({ companyId: that.supplierCompanyId }).then(res => {
        if (res.length) {
          that.demandCompanies = res
          that.demandCompanyId = res[0].id
        }
        that.handleSearch()
      })
    },
    // 派单公司发生变更
    demandComponyChange () {
      const that = this
      if (that.currentUserType === 3) {
        that.handleSearch()
      } else {
        that.initSupplierCompanies()
      }
    },
    // 初始化供应公司列表
    initSupplierCompanies () {
      const that = this
      that.supplierCompanies = []
      that.supplierCompanyId = 0
      getSupplierCompany({ dispatcherId: that.demandCompanyId }).then(res => {
        if (res.length) {
          that.supplierCompanies = res
          that.supplierCompanyId = res[0].id
        }
        that.handleSearch()
      })
    },
    handleSummary ({ columns, data }) {
      const sums = {}
      columns.forEach((column, index) => {
        const key = column.key
        if (index === 0) {
          sums[key] = {
            key,
            value: '合计'
          }
          return
        }
        const values = data.map(item => Number(item[key]))
        if (!values.every(value => isNaN(value))) {
          const v = values.reduce((prev, curr) => {
            const value = Number(curr)
            if (!isNaN(value)) {
              return prev + curr
            } else {
              return prev
            }
          }, 0)
          sums[key] = {
            key,
            value: toMoney(v)
          }
        } else {
          sums[key] = {
            key,
            value: '-'
          }
        }
      })
      return sums
    },

    handleSearch () {
      this.currentQuery = {
        demandCompanyId: this.demandCompanyId,
        supplierCompanyId: this.supplierCompanyId,
        publisherId: this.publisherId,
        year: new Date(this.year).getFullYear().toString()
      }
      this.initData()
    },

    initData () {
      this.dataLoading = true
      getSupplierBillList(this.currentQuery).then(res => {
        this.dataLoading = false
        if (res && !res.errcode) {
          this.tableData = res
        } else {
          this.tableData = []
        }
      }).catch(() => {
        this.dataLoading = false
      })
    }
  }
}
</script>
